/* eslint-disable no-var, no-console */
import { SocketChannel } from '~/modules/SDK/socket/SocketChannel'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const settlement_line = createIndicator({
  id: 'settlementline',
  displayName: '月結算',
  enabledOn(symbol, data, channel) {
    return data?.type === channel.tw_futures
  },
  constructorScope: {
    init(context, inputCallback) {
      const ticker = this.PineJS.Std.ticker(this._context)
        .toString()
        .replace(/[^\w-]/g, '')
      const symbol = ticker + '#foreigner_txf'
      this._context.new_sym(symbol, this.PineJS.Std.period(this._context))
    },
    main(context, inputCallback) {
      this._context.select_sym(1)

      //日期
      const time = this.PineJS.Std.time(this._context)

      //日期處理
      const getDay = new Date(time).getDay()
      const getDayArray = this._context.new_var(getDay)
      const getMonth = new Date(time).getMonth()
      //月份比較
      const monthCompare = this._context.new_var()

      //每月幾號
      const MonthOfDay = new Date(time).getDate()

      //籌碼
      const chipsDiff = this.PineJS.Std.close(this._context)
      const sumChipsDiff = this._context.new_var()

      //月結算的標記
      const mark = this._context.new_var()

      //儲存當日月份
      monthCompare.set(getMonth)

      //換月時將結算標記歸零
      if (monthCompare.get(0) !== monthCompare.get(1)) {
        mark.set(0)
      }

      //月結算邏輯 , 當月結Mark = 0 時才會判斷
      const checkDay3 = mark.get(0) === 0 && getDay === 3 && MonthOfDay >= 15 && MonthOfDay <= 21
      const checkDay4 = mark.get(0) === 0 && getDay === 4 && MonthOfDay >= 16 && MonthOfDay <= 22
      const checkDay5 = mark.get(0) === 0 && getDay === 5 && MonthOfDay >= 17 && MonthOfDay <= 23
      const checkDay1 = mark.get(0) === 0 && getDay === 1 && MonthOfDay >= 20 && MonthOfDay <= 26
      const checkDay2 = mark.get(0) === 0 && getDay === 2 && MonthOfDay >= 21 && MonthOfDay <= 27
      const isCheckDay = checkDay3 || checkDay4 || checkDay5 || checkDay1 || checkDay2

      //如果是月結算 月結Mark標記為1 ,然後將在下次換月時 Mark標記才會歸零
      if (isCheckDay) {
        sumChipsDiff.set(20000)
        mark.set(1)
      } else {
        sumChipsDiff.set(NaN)
      }

      const value = this.PineJS.Std.sma(sumChipsDiff, 1, this._context)

      return [value]
    },
  },
  metainfo: {
    _metainfoVersion: 40,
    scriptIdPart: '',
    is_hidden_study: false,
    is_price_study: true,
    isCustomIndicator: true,

    plots: [{ id: 'plot_0', type: 'histogram' }],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 1,
          visible: true,
          linewidth: 1,
          plottype: 1,
          trackPrice: false,
          transparency: 40,
          color: '#999999',
        },
      },
      precision: 0,
      inputs: {},
    },
    styles: {
      plot_0: {
        title: '月結算',
        histogramBase: 0,
      },
    },
    inputs: [],
  },
})
